<style lang="less" scoped>
.herder{
  min-width: 1230px;
  // height: 200px;
  // line-height: 200px;
  // text-align: center;
  // background-color: aquamarine;
}
.herder-top{
  height: 40px;
  line-height: 40px;
  color: #ffffff;
  background-color: #155BDD;
}
.herder-top-cont{
  width: 1200px;
  margin: auto;
}
.herder-cont{
  height: 230px;
  position: relative;
  background-size: auto 101%;
  background-position: center;
  background-repeat:no-repeat;
  // background-image: url(https://www.路航通.com/api/system/static/1537277/153/10/8d45f0dd8b504738b98b1d088b8acd14.jpg);
}
.herder-cont-box{
  // padding-top: 55px;
  position: relative;
}
.herder-cont-box-img{
  height: 125px;
  width: 800px;
  display: block;
  margin: auto;
}
.herder-nav{
  color: #ffffff;
  margin-top: 60px;
  border-radius: 6px;
  overflow: hidden;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}
.nav-top{
  height: 56px;
  line-height: 56px;
  background-color: #155BDD;
}
.nav-top-item{
  height: 100%;
  text-align: center;
  float: left;
  font-size: 16px;
  position: relative;
}
.nav-top-item:hover{
  font-weight: bold;
}
.nav-top-item-on{
  font-weight: bold;
  position: relative;
}
.nav-top-item-on::after{
  content: '';
  height: 3px;
  width: 100%;
  background-color: #FF9852;
  position: absolute;
  bottom: 0;
  left: 0;
}
.herder-bot{
  height: 50px;
  padding: 7px 10px 0 10px;
  background-color: #508AFE;
}
.search-input{
  height: 36px;
  border-radius: 4px;
  padding: 0 10px;
  width: 200px;
  font-size: 15px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}
.msg-box{
  padding: 7px 0 0 40px;
  position: relative;
}
.msg-icon{
  height: 22px;
  width: 22px;
  position: absolute;
  top: 7px;
  left: 0;
}
.msg-icon img{
  display: block;
  height: 100%;
  width: 100%;
}
.msg-cont{
  height: 22px;
  overflow: hidden;
}
.slide-box{
  position: relative;
  transition: all .3s;
}
.slide-item{
  width: 500px;
  font-size: 14px;
  height: 22px;
  line-height: 22px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}
.me-icon{
  height: 16px;
  width: 16px;
  margin-right: 5px;
  vertical-align: middle;
}
.nav-number{
  min-width: 20px;
  height: 20px;
  padding: 0 3px;
  border-radius: 10px;
  background-color: #ff4136;
  line-height: 20px;
  font-size: 12px;
  color: #ffffff;
  position: absolute;
  top: 2px;
  right: 1px;
  font-weight: 100;
}
</style>
<template>
  <div class="herder">
    <div class="herder-top">
      <div class="herder-top-cont clearfix">
        <div class="pull-left">
          <!-- <span v-if="caWebVisitsNumber > 0">网站访问量：{{caWebVisitsNumber}} 次</span> -->
        </div>
        <div class="pull-right">
          <span v-if="userName">您好，{{userName}}</span>
          <span v-else class="cursor" @click="toLogin">请登录</span>
          <span v-if="userName" class="cursor" style="padding-left: 25px;" @click="quit">
            <img :src="me" alt="" class="me-icon">
            <span style="opacity: 0.8;">退出登录</span>
          </span>
        </div>
      </div>
    </div>
    <!-- <div class="herder-cont" :style="`background-image: url(${bannerImage});`" @click="aaa"> -->
    <div class="herder-cont" @click="aaa">
      <div class="herder-top-cont herder-cont-box" style="position: relative;height: 100%;">
        <!-- <img :src="logeA" alt="" class="herder-cont-box-img"> -->
        <div class="clearfix" style="line-height: 66px; padding-top: 22px;">
          <img class="pull-left" src="@/assets/images/new_logo.png" alt="" >
          <div class="pull-right">
              <input class="search-input" type="text" v-model="searchValue" placeholder="请输入关键词">
              <button v-if="navIndex != 0" class="btn search" @click="search('1')">模块搜索</button>
              <button class="btn search" @click="search('2')">搜全站</button>
            </div>
        </div>
        <div class="herder-nav">
          <ul class="nav-top clearfix">
            <li v-for="(item, index) of arr" :key="index" class="nav-top-item" :style="`width: ${1200 / arr.length}px;`">
              <span class="cursor" :class="{'nav-top-item-on': index == navIndex}" @click="navItemClick(index, item)">{{item.name}}</span>
              <div v-if="arr.length - 1 == index && memberNumber > 0" class="nav-number">
                <span v-if="memberNumber < 100">{{memberNumber}}</span>
                <span v-else>99+</span>
              </div>
            </li>
          </ul>
          <div class="herder-bot clearfix">
            <div class="pull-left msg-box">
              <div class="msg-cont">
                <div class="slide-box" :style="`transform: translateY(${-(msgIndex * 22)}px);`">
                  <div v-for="(item, index) of msgArr" :key="index" class="slide-item" @click="toDetail(item.id)">{{item.title}}</div>
                </div>
              </div>
              <div class="msg-icon">
                <img :src="labaImage" alt="">
              </div>
            </div>
            <!-- <div class="pull-right">
              <input class="search-input" type="text" v-model="searchValue" placeholder="请输入关键词">
              <button v-if="navIndex != 0" class="btn search" @click="search('1')">模块搜索</button>
              <button class="btn search" @click="search('2')">全站搜索</button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <Dialog v-model="dialogShow" width="400px" title="登录" @shut="shutFun">
      <div style="padding: 0px 10px 10px 10px;">
      <TableFrom ref="from" :data="userForm" v-loading="loading">
        <TableInput
          :height="48"
          :marginBottom="25"
          label="用户名"
          :labelWidth="0"
          required
          :icon="iconA"
          placeholder="请输入用户名"
          v-model="userForm.account"
        ></TableInput>
        <TableInput
          :height="48"
          :labelWidth="0"
          :marginBottom="20"
          label="密码"
          required
          type="password"
          :icon="iconB"
          placeholder="请输入密码"
          v-model="userForm.password"
        ></TableInput>
      </TableFrom>
      <div class="btns clearfix" style="padding: 0;">
        <Buttom class="btn loging" style="background-color: #155BDD;margin-left: 0;width: 100%;" :loading="loading" @click.native="submitForm">
          <span>登录</span>
        </Buttom>
        <!-- <Buttom class="btn loging" style="background-color: #155BDD;" :loading="loading" @click.native="submitForm">
          <span>登录</span>
        </Buttom> -->
      </div>
      </div>
    </Dialog>
  </div>
</template>
<script>
import { getNavAll, quitLogin, getList, noticeCount, caWebVisits } from '@/api/web'
import { LoginAdminLogin, GetNikeName } from '@/api/login.js'
import TableFrom from '@/components/from/TableFrom'
import Dialog from '@/components/dialog/Dialog.vue'
import TableInput from '@/components/from/TableInput'
import md5 from 'js-md5'
const Base64 = require('js-base64').Base64
export default {
  name: 'Herder',
  components: {
    Dialog,
    TableFrom,
    TableInput
  },
  data () {
    return {
      fcType: null,
      adList: [],
      labaImage: require('../../assets/images/laba.png'),
      // bannerImage: require('../../assets/images/aaa.jpg'),
      bannerImage: require('../../assets/images/aab.jpg'),
      // bannerImage: require('../../assets/images/banner_a.jpg'),

      logeA: require('../../assets/images/logo_a.png'),
      me: require('../../assets/images/me.png'),
      iconA: require('../../assets/images/login_z.png'),
      iconB: require('../../assets/images/login_p.png'),
      caWebVisitsNumber: 0,
      userName: '',
      arr: [],
      navIndex: 0,
      msgArr: [],
      msgIndex: 0,
      searchValue: '',
      interavl: null,
      memberNumber: 0,
      loading: false,
      userForm: {
        account: '',
        password: ''
      },
      dialogShow: false
    }
  },
  watch: {
    $route: function (n, o) {
      this.watchRoute(this.arr)
    }
  },
  props: {},
  created () {
    this.getName()
    if (this.$route.query.text) {
      this.searchValue = this.$route.query.text
    }
    this.getCaWebVisits()
  },
  beforeDestroy () {
    clearInterval(this.interavl)
  },
  methods: {
    aaa () {
    },
    toLogin () {
      this.dialogShow = true
    },
    submitForm () {
      this.loading = true
      this.$refs.from.fromVerify().then(resp => {
        if (resp.code !== '000') {
          this.loading = false
          return
        }
        const token = 'Basic ' + Base64.encode(this.userForm.account + ':' + md5(this.userForm.password))
        window.localStorage.setItem('token', token)
        LoginAdminLogin({}).then(res => {
          this.loading = false
          if (res.code === '000') {
            this.dialogShow = false
            this.userForm = {
              account: '',
              password: ''
            }
            this.$msg('登录成功', 'success')
            window.localStorage.setItem('token', res.result.tokenType + ' ' + res.result.accessToken)
            window.localStorage.setItem('loginTime', new Date().getTime())
            GetNikeName().then(resp => {
              if (res.code === '000') {
                console.log(resp.result.nickName)
                window.localStorage.setItem('loginName', resp.result.nickName)
                this.getName()
              }
            })
          }
        })
      })
    },
    shutFun (e) {
      this.userForm = {
        account: '',
        password: ''
      }
    },
    search (type) {
      if (!this.searchValue) {
        return
      }
      if (type === 1) {
        if (this.navIndex === 0) {
          return
        }
      }
      if (this.$route.query.text === this.searchValue && this.$route.query.type === type) {
        return
      }
      this.$router.push({ path: '/search?id=' + this.arr[this.navIndex].id + '&type=' + type + '&text=' + this.searchValue })
    },
    navItemClick (index, item) {
      if (this.navIndex === index) {
        const pathList = ['/search', '/friendship', '/contact']
        if (!pathList.includes(this.$route.path)) {
          return
        }
      }
      this.navIndex = index
      if (item.isTarget === '1') {
        if (item.url === '/') {
          this.openHref(item.url, 1)
          return
        }
        if (item.url === '/member') {
          this.openHref(`${item.url}?id=${item.id}`, 1)
          return
        }
        if (item.url.substring(0, 4) === 'http') {
          this.openHref(item.url, 2)
          return
        }
        this.openHref(`/list?id=${item.id}`, 1)
      } else {
        if (item.url === '/') {
          this.$router.push({ path: item.url })
          return
        }
        if (item.url === '/member') {
          this.$router.push({ path: item.url, query: { id: item.id } })
          return
        }
        if (item.url.substring(0, 4) === 'http') {
          window.location.href = item.url
          return
        }
        this.$router.push({ path: '/list', query: { id: item.id } })
      }
    },
    openHref (url, flag) {
      if (flag === 1) {
        const { href } = this.$router.resolve({
          path: url
        })
        window.open(href, '_blank')
      } else {
        window.open(url, '_blank')
      }
    },
    toDetail (id) {
      const { href } = this.$router.resolve({
        path: '/detail',
        query: { id: this.$route.query.id, articleId: id }
      })
      window.open(href, '_blank')
    },
    initialData () {
      getNavAll({}).then(res => {
        const data = res.result
        const arr = []
        data.forEach((element, index) => {
          if (element.isHide === '1') {
            if (element.preLoginHide === 2) {
              if (this.userName) {
                arr.push(element)
              }
            } else {
              arr.push(element)
            }
          }
          if (element.code === 'tzgg') {
            this.getggAll(element.id)
          }
          if (element.code === 'hyzq') {
            this.$store.commit('setHytpUrl', `${element.url}?id=${element.id}&cid=${element.children[1].id}`)
          }
        })
        this.arr = arr
        this.watchRoute(arr)
      })
    },
    getggAll (id) {
      const that = this
      const data = {
        cmsType: id,
        pageSize: 5,
        pageNo: 1
      }
      getList(data).then(res => {
        if (res.code === '000') {
          that.msgArr = res.result.records
          that.interavl = setInterval(() => {
            if (that.msgIndex === that.msgArr.length - 1) {
              that.msgIndex = 0
            } else {
              that.msgIndex++
            }
          }, 5000)
        }
      })
    },
    getName () {
      if (window.localStorage.getItem('loginName')) {
        if (new Date().getTime() - parseInt(window.localStorage.getItem('loginTime')) < 1000 * 59 * 12) {
          this.geiWeidu()
          this.userName = window.localStorage.getItem('loginName')
          this.initialData()
        } else {
          this.userName = ''
          this.initialData()
        }
      } else {
        this.userName = ''
        this.initialData()
      }
    },
    geiWeidu () {
      noticeCount({}).then(res => {
        if (res.code === '000') {
          this.$parent.uncleaned = res.result.vuteCount
          this.$parent.uncleanedShow = true
          if (res.result.vuteCount + res.result.noticeCount > 0) {
            this.memberNumber = res.result.vuteCount + res.result.noticeCount
          }
        }
      })
    },
    watchRoute (arr) {
      for (let i = 0; i < arr.length; i++) {
        if (this.$route.path === arr[i].url) {
          if (this.$route.query.id) {
            if (this.$route.query.id === arr[i].id) {
              this.navIndex = i
              break
            }
          } else {
            this.navIndex = i
            break
          }
        }
        if (this.$route.query.id === arr[i].id) {
          this.navIndex = i
          break
        }
      }
    },
    // 获取网站统计访问数量
    getCaWebVisits () {
      caWebVisits({ cmsType: true }).then(res => {
        if (res.code === '000') {
          this.caWebVisitsNumber = res.result.visits
        }
      })
    },
    // 退出登录
    quit () {
      this.$confirm(['退出', '是否要退出登录'], (res) => {
        quitLogin({}).then(resp => {
          if (resp.code === '000') {
            localStorage.clear()
            sessionStorage.clear()
            this.getName()
            console.log(this.$parent.uncleanedShow)
            this.$parent.windowsHideFun()
            this.memberNumber = 0
          }
        })
      })
    }
  }
}
</script>
